import React, { useState, useEffect } from "react"
import CrumbTitleLayout from "../layouts/crumbtitlelayout"
import { GROUPS } from "../constants"
//import TypesFilter from "../components/people/typesfilter"
import ShowSmall from "../components/showsmall"
import Container from "../components/container"
import FlHdDiv from "../components/flhddiv"
import useImageMap from "../hooks/imagemap"
import { graphql } from "gatsby"
import FullDiv from "../components/fulldiv"
import HTMLDiv from "../components/htmldiv"
import useBreakpoints from "../hooks/breakpoints"
import PersonHeaderHoz from "../components/people/personheaderhoz"
import ContactCard from "../components/people/contactcard"
import PersonHeader from "../components/people/personheader"
import Tabs from "../components/tabs/tabs"
import PeopleGrid from "../components/people/peoplegrid"
import AltView from "../components/altview"
import {
  About,
  AdminTitles,
  Publications,
  SectionCard,
  SideContactCard,
} from "./allfacultytemplate"
import MainSideCol from "../components/mainsidecol"
import usePublications from "../hooks/publications"
import FlatCard from "../components/flatcard"
import Abstract from "../components/abstract"
import MainCard from "../components/maincard"
import VertTabs from "../components/tabs/verttabs"
import useContextName from "../hooks/contextname"
import BlueIndexLink from "../components/buttons/blueindexlink"
import BlueLink from "../components/buttons/bluelink"
import PageLayout from "../layouts/pagelayout"

const EMPTY_QUERY = ""

type PersonProps = {
  path: string
  person: any
  allPublications: Array<any>
  imageMap: any
}

const Person: React.FC<PersonProps> = ({
  path,
  person,
  allPublications,
  imageMap,
}) => {
  const titles = useContextName("admin", person.titleMap, true)
  const adminTitles = titles !== "" ? titles.split(";") : []

  return (
    <MainSideCol>
      <FullDiv>
        <AltView size="md">
          <>
            <PersonHeader person={person} imageMap={imageMap} />
            <div className="mt-8">
              <div className="mt-8 bg-gray-100 p-4 px-8">
                {/* <Abstract h="h-48"> */}
                <About data={person} />
                {/* </Abstract> */}
              </div>
            </div>
          </>

          <MainCard>
            <PersonHeaderHoz person={person} imageMap={imageMap} />
            <div className="mt-4">
              <HTMLDiv o={person} />
            </div>
          </MainCard>
        </AltView>

        {adminTitles.length > 0 && <AdminTitles titles={adminTitles} />}

        <ShowSmall size="xl">
          <SectionCard name="Contact" className="mt-10">
            <ContactCard person={person} />
          </SectionCard>
        </ShowSmall>

        <Publications
          person={person}
          allPublications={allPublications}
          showAbstract={false}
          showMoreButton={true}
        />
      </FullDiv>

      <div className="md:pl-10">
        <SideContactCard title="Contact">
          <ContactCard person={person} />

          <h5 className="mt-24 mb-2">Links</h5>
          <BlueLink to={`${path}/publications`}>Publications</BlueLink>
        </SideContactCard>
      </div>
    </MainSideCol>
  )
}

type PeopleTemplateProps = {
  path: string
  pageContext: any
  data: any
}

const PeopleTemplate: React.FC<PeopleTemplateProps> = ({
  path,
  pageContext,
  data,
}) => {
  const { title, crumbs, person, groupMap } = pageContext

  const [query, setQuery] = useState(EMPTY_QUERY)
  const [filteredGroupMap, setFilteredGroupMap] = useState({})
  const breakpoint = useBreakpoints()
  //const [selectedPublications, setSelectedPublications] = useState([])
  //const [page, setPage] = useState(1)
  //const [recordsPerPage, setRecordsPerPage] = useState(20)
  //const [types, setTypes] = useState([])

  const imageMap = useImageMap(data)

  // const allPublications =
  //   data.allPublications.edges.length > 0
  //     ? data.allPublications.edges[0].node.publications
  //     : []

  // useEffect(() => {
  //   if (person !== null) {
  //     usePublications(setSelectedPublications, person.frontmatter.id)
  //   }
  // }, [])

  const selectedPublications =
    data.allPublications.edges.length > 0
      ? data.allPublications.edges[0].node.publications
      : []

  //const selectedPublications = usePublications(usePersonSelectedPubsUrl(person))
  //const allPublications = usePublications(usePersonPubsUrl(person))

  useEffect(() => {
    if (query !== "") {
      let ret: any = {}

      for (let g of GROUPS) {
        if (g in groupMap) {
          const people = []

          for (let person of groupMap[g]) {
            if (person.frontmatter.name.toLowerCase().includes(query)) {
              people.push(person)
            }
          }

          if (people.length > 0) {
            ret[g] = people
          }
        }
      }

      setFilteredGroupMap(ret)
      //setPage(1)
    }
  }, [query])

  const handleSearch = (text: string, clicked: boolean) => {
    // update state according to the latest query and results
    setQuery(text)
  }

  const hasSearchResults = query !== EMPTY_QUERY
  let people = hasSearchResults ? filteredGroupMap : groupMap

  const tabs = []

  tabs.push(
    <div id="Faculty" key="faculty">
      {"faculty::Director" in groupMap && (
        <div id="Directors" key="directors">
          <h2 className="mb-8">Director</h2>
          <PeopleGrid
            name={"Director"}
            imageMap={imageMap}
            people={groupMap["faculty::Director"]}
            showHeadings={false}
          />
        </div>
      )}

      {"faculty::Members" in groupMap && (
        <div id="Members" key="members" className="mt-8">
          <h2 className="mb-8">Members</h2>
          <PeopleGrid
            name={"Members"}
            imageMap={imageMap}
            people={groupMap["faculty::Members"]}
            showHeadings={false}
          />
        </div>
      )}

      {"faculty::Associate Members" in groupMap && (
        <div id="Associate Members" key="associate-members" className="mt-8">
          <h2 className="mb-8">Associate Members</h2>
          <PeopleGrid
            name={"Associate Members"}
            imageMap={imageMap}
            people={groupMap["faculty::Associate Members"]}
            showHeadings={false}
          />
        </div>
      )}
    </div>
  )

  tabs.push(
    <div id="Research Staff" key="research-staff">
      <h2 className="mb-8">Research Staff</h2>
      <PeopleGrid
        name={"Research Staff"}
        imageMap={imageMap}
        people={groupMap["Research Staff"]}
        showHeadings={false}
        photoMode={""}
        showUrl={true}
        colWidth={"w-full sm:w-9/20 lg:w-3/10 xl:w-22/100"}
      />
    </div>
  )

  let students = []

  if ("Graduate Students" in groupMap) {
    students.push(
      <div id="Graduate Students" key="graduate-students">
        <h2 className="mb-8">Graduate Students</h2>
        <PeopleGrid
          name={"Graduate Students"}
          imageMap={imageMap}
          people={groupMap["Graduate Students"]}
          showHeadings={false}
          photoMode={""}
          showUrl={true}
          colWidth={"w-full sm:w-9/20 lg:w-3/10 xl:w-22/100"}
        />
      </div>
    )
  }

  if ("Masters Students" in groupMap) {
    students.push(
      <div
        id="Masters Students"
        key="masters-students"
        className={students.length > 0 ? "mt-8" : ""}
      >
        <h2 className="mb-8">Masters Students</h2>
        <PeopleGrid
          name={"Masters Students"}
          imageMap={imageMap}
          people={groupMap["Masters Students"]}
          showHeadings={false}
          photoMode={""}
          showUrl={true}
          colWidth={"w-full sm:w-9/20 lg:w-3/10 xl:w-22/100"}
        />
      </div>
    )
  }

  if ("Undergraduate Students" in groupMap) {
    students.push(
      <div
        id="Undergraduate Students"
        key="undergraduate-students"
        className={students.length > 0 ? "mt-8" : ""}
      >
        <h2 className="mb-8">Undergraduate Students</h2>
        <PeopleGrid
          name={"Undergraduate Students"}
          imageMap={imageMap}
          people={groupMap["Undergraduate Students"]}
          showHeadings={false}
          photoMode={""}
          showUrl={true}
          colWidth={"w-full sm:w-9/20 lg:w-3/10 xl:w-22/100"}
        />
      </div>
    )
  }

  if (students.length > 0) {
    tabs.push(
      <div id="Students" key="students">
        {students}
      </div>
    )
  }

  return (
    <PageLayout path={path} title={title} crumbs={crumbs} index={false}>
      <FlHdDiv>
        <Container>
          {person !== null && query === "" ? (
            <Person
              path={path}
              person={person}
              allPublications={selectedPublications}
              imageMap={imageMap}
            />
          ) : (
            <VertTabs>{tabs}</VertTabs>
          )}
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default PeopleTemplate

export const query = graphql`
  query ($id: String!) {
    images: allFile(
      filter: {
        absolutePath: { regex: "/images/people/500x500/rounded/" }
        ext: { regex: "/png/" }
      }
    ) {
      edges {
        node {
          name
          ext
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 500, aspectRatio: 1)
          }
        }
      }
    }

    genericPersonImage: file(
      absolutePath: { regex: "/images/people/500x500/rounded/generic.png/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, aspectRatio: 1)
      }
    }

    allPublications: allPublicationsJson(filter: { person: { eq: $id } }) {
      edges {
        node {
          person
          publications {
            pmid
            pmcid
            doi
            title
            abstract
            authorList
            peopleList
            journal
            issue
            pages
            volume
            year
            month
            day
            url
            tagList
          }
        }
      }
    }

    selectedPublications: allSelectedPublicationsJson(
      filter: { person: { eq: $id } }
    ) {
      edges {
        node {
          person
          publications {
            pmid
            pmcid
            doi
            title
            abstract
            authorList
            peopleList
            journal
            issue
            pages
            volume
            year
            month
            day
            url
            tagList
          }
        }
      }
    }
  }
`
